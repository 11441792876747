import { useAtomState } from '@zedux/react'
import invoiceAtom from './invoiceAtom'
import { description_width } from './helpers'

const InvoiceLinesHeader = () => {

  const [invoice] = useAtomState(invoiceAtom)

  if (!invoice) return null

  return (
    <div className="row my-2 py-2 border-top border-bottom border-success">
      <div className={`col-lg-${description_width(invoice)}`}>
        <label>Description:</label>
      </div>
      {invoice.settings.use_qty_price ? <div className="col-lg-1 text-end"><label>Qty:</label></div> : null}
      {invoice.settings.use_units ? <div className="col-lg-1"><label>Units:</label></div> : null}
      {invoice.settings.use_qty_price ? <div className="col-lg-1 text-end"><label>Price:</label></div> : null}
      {invoice.settings.use_vat ? <div className="col-lg-2"><label>VAT rate:</label></div> : null}
      <div className="col-lg-2 text-end"><label>Amount:</label></div>
      <div className="col-lg-1"></div>
    </div>
  );
}

export default InvoiceLinesHeader;