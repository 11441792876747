import { useAtomState } from "@zedux/react"
import { useState, useEffect } from "preact/hooks"
import { BsChevronLeft, BsCheckSquare, BsChevronRight, BsChevronDown } from "react-icons/bs";
import invoiceAtom from "./invoiceAtom"
import invoiceEditorModeAtom from "./invoiceEditorModeAtom"
import BusinessRecordDetails from "./BusinessRecordDetails"
import businessesListAtom from "./businessesListAtom"

const BusinessRecord = ({ business }) => {
  const [businesses] = useAtomState(businessesListAtom)
  const [isExpanded, setIsExpanded] = useState(false)
  const [invoiceEditorMode, setInvoiceEditorMode] = useAtomState(invoiceEditorModeAtom)
  const [invoice, setInvoice] = useAtomState(invoiceAtom)
  const [isEditing, setIsEditing] = useState(false)

  const selectBusiness = () => {
    const savedBusiness = businesses.find(b => b.id === business.id)
    setInvoice({
      ...invoice,
      supplier_data: savedBusiness.data.lines_for_invoice.join("\n"),
      payment_instructions: savedBusiness.data.lines_for_payment_instructions.join("\n"),
      settings: savedBusiness.data.invoice_settings,
      updated: true,
      business: savedBusiness,
      business_id: savedBusiness.id,
      business_is_preselected: true
    })
    setInvoiceEditorMode("edit_invoice")
  }
  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex">
          <div className="btn btn-outline-success" onClick={selectBusiness}>
            Select
          </div>
          <div className="p-2 flex-grow-1">{business.name}</div>
          <div className="btn btn-sm p-1">
            {
              isExpanded || (invoice.business_id === business.id) ?
                <div
                  className="btn btn-outline-info btn-sm"
                  onClick={() => setIsExpanded(false)}
                >
                  <BsChevronDown />
                </div> :
                <div
                  className="btn btn-outline-info btn-sm"
                  onClick={() => setIsExpanded(true)}
                >
                  <BsChevronRight />
                </div>
            }
          </div>
        </div>
      </div>
      {
        isExpanded || (invoice.business_id === business.id) && <BusinessRecordDetails business={business} />
      }
    </div>
  )
}

export default BusinessRecord