import { useAtomState } from '@zedux/react'
import invoiceAtom from './invoiceAtom'

// For CIS
const TotalsCis = () => {
  const [invoice] = useAtomState(invoiceAtom)

  const vatLines = () => {
    if (invoice.settings.use_vat === false) {
      return null
    }

    if (invoice.totals.vat === undefined) {
      return null
    }

    let index = 0
    return invoice.totals.vat.map((line) => {
      index += 1
      return (
        <tr key={index}>
          <td className="text-end">VAT at {line.rate * 100.0}%:</td>
          <td className="text-end">{line.vat}</td>
        </tr>
      )
    })
  }

  return (
    <table className="table">
      <tr>
        <td className="text-end">Subtotal:</td>
        <td className="text-end">{invoice.totals.subtotal}</td>
      </tr>
      {
        invoice.totals.materials > 0.0 ? (
          <tr>
            <td className="text-end">Materials:</td>
            <td className="text-end">{invoice.totals.cis_materials}</td>
          </tr>
        ) : null
      }
      {
        invoice.totals.materials > 0.0 ? (
          <tr>
            <td className="text-end">Labour:</td>
            <td className="text-end">{invoice.totals.subtotal - invoice.totals.cis_materials}</td>
          </tr>
        ) : null
      }
      <tr>
        <td className="text-end">Deduction rate:</td>
        <td className="text-end">{invoice.settings.cis_deduction_rate} %</td>
      </tr>
      <tr>
        <td className="text-end">Deduction:</td>
        <td className="text-end">{invoice.totals.cis_deduction}</td>
      </tr>
      {
        invoice.settings.use_vat ? (
          <tr>
            <td colspan="2" className="text-end"><b>Domestic reverse charge applies</b></td>
          </tr>
        ) : null
      }
      {vatLines()}
      <tr>
        <td className="text-end">Total amount payable:</td>
        <td className="text-end">{invoice.totals.cis_payable}</td>
      </tr>
    </table>
  );
}

export default TotalsCis;