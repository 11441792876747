import { useEffect } from 'preact/hooks';
import { Stack, useTheme } from '@mui/material';
import { useAtomState } from '@zedux/react';

import { useInspectorDrawerOpen, useLeftDrawerOpen } from '../documents/editor/EditorContext';

import InspectorDrawer, { INSPECTOR_DRAWER_WIDTH } from './InspectorDrawer';
import LeftDrawer, { LEFT_DRAWER_WIDTH } from './LeftDrawer';
import TemplatePanel from './TemplatePanel';
import mailSubjectAtom from '../atoms/mailSubjectAtom.js';
import mailSubTitleAtom from '../atoms/mailSubTitleAtom.js';
import mailTemplateIdAtom from '../atoms/mailTemplateIdAtom.js';
import galleryImagesAtom from '../atoms/galleryImagesAtom.js';

// interface EmailEditorProps {
//   id: number;
//   name: string;
//   body_json: string;
// }

function useDrawerTransition(cssProperty: 'margin-left' | 'margin-right', open: boolean) {
  const { transitions } = useTheme();
  return transitions.create(cssProperty, {
    easing: !open ? transitions.easing.sharp : transitions.easing.easeOut,
    duration: !open ? transitions.duration.leavingScreen : transitions.duration.enteringScreen,
  });
}

export default function Editor({ id, name, subject, subtitle, available_vars, images }) {
  const inspectorDrawerOpen = useInspectorDrawerOpen();
  const leftDrawerOpen = useLeftDrawerOpen();

  const [_subject, setSubject] = useAtomState(mailSubjectAtom);
  const [_name, setSubTitle] = useAtomState(mailSubTitleAtom);
  const [_templateId, setTemplateId] = useAtomState(mailTemplateIdAtom);
  const [_galleryImages, setGalleryImages] = useAtomState(galleryImagesAtom);

  const marginLeftTransition = useDrawerTransition('margin-left', leftDrawerOpen);
  const marginRightTransition = useDrawerTransition('margin-right', inspectorDrawerOpen);

  useEffect(() => {
    setSubject(subject);
    setSubTitle(subtitle);
    setTemplateId(id);
    setGalleryImages(images);
  }, [subject]);

  return (
    <>
      <InspectorDrawer />
      <LeftDrawer id={id} name={name} available_vars={available_vars} />

      <Stack
        sx={{
          marginRight: inspectorDrawerOpen ? `${INSPECTOR_DRAWER_WIDTH}px` : 0,
          marginLeft: leftDrawerOpen ? `${LEFT_DRAWER_WIDTH}px` : 0,
          transition: [marginLeftTransition, marginRightTransition].join(', '),
        }}
      >
        <TemplatePanel />
      </Stack>
    </>
  );
}
