import invoiceAtom from './invoiceAtom'
import { useAtomState, useAtomInstance } from '@zedux/react'
import editSettingsAtom from './editSettingsAtom'

const InvoiceSettingsEditor = () => {
  const [invoice] = useAtomState(invoiceAtom)
  const { update_settings } = useAtomInstance(invoiceAtom).exports
  const [editingSettings, setEditingSettings] = useAtomState(editSettingsAtom)

  return (
    <>
      <div className="clearfix">
      </div>
      <div className="alert alert-info">
        <div className="float-end">
          <div className="btn btn-outline-secondary btn-sm" onClick={() => setEditingSettings(false)}>X</div>
        </div>
        <div className="row">
          <div className="col-lg-2 py-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="true"
                checked={invoice && invoice.settings && invoice.settings.use_vat}
                onChange={(ev) => update_settings({ use_vat: ev.target.checked })}
              />
              <label class="form-check-label">Use VAT</label>
            </div>
          </div>
          <div className="col-lg-3 py-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="true"
                checked={invoice && invoice.settings && invoice.settings.use_purchase_order}
                onChange={(ev) => update_settings({ use_purchase_order: ev.target.checked })}
              />
              <label class="form-check-label">Use purchase order #</label>
            </div>
          </div>
          <div className="col-lg-3 py-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="true"
                checked={invoice && invoice.settings && invoice.settings.use_deliver_to}
                onChange={(ev) => update_settings({ use_deliver_to: ev.target.checked })}
              />
              <label class="form-check-label">Use "deliver to"</label>
            </div>
          </div>
          <div className="col-lg-3 py-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="true"
                checked={invoice && invoice.settings && invoice.settings.use_separate_supply_date}
                onChange={(ev) => update_settings({ use_separate_supply_date: ev.target.checked })}
              />
              <label class="form-check-label">Separate supply date</label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-2 py-2">
            <label>Lines settings:</label>
          </div>
          <div className="col-lg-3 py-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="true"
                checked={invoice && invoice.settings && invoice.settings.use_qty_price}
                onChange={(ev) => update_settings({ use_qty_price: ev.target.checked })}
              />
              <label class="form-check-label">Use Quantity and Price</label>
            </div>
          </div>
          <div className="col-lg-2 py-2">
            <div class="form-check ms-3">
              <input
                class="form-check-input"
                type="checkbox"
                value="true"
                checked={invoice && invoice.settings && invoice.settings.use_units}
                onChange={(ev) => update_settings({ use_units: ev.target.checked })}
              />
              <label class="form-check-label">Use units</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 py-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="true"
                checked={invoice && invoice.settings && invoice.settings.use_cis}
                onChange={(ev) => update_settings({ use_cis: ev.target.checked })}
              />
              <label class="form-check-label">Use CIS</label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvoiceSettingsEditor