import { FaRegTrashCan } from "react-icons/fa6";
import invoiceAtom from "./invoiceAtom"
import { useAtomState, useAtomInstance } from "@zedux/react"
import { useRef, useEffect } from "preact/hooks"

const InvoiceLine = ({ index }) => {
  const [invoice, setInvoice] = useAtomState(invoiceAtom)
  const { update_line, delete_line } = useAtomInstance(invoiceAtom).exports
  const descriptionRef = useRef(null);

  useEffect(() => {
    adjustTextareaHeight(descriptionRef.current);
  }, []);

  const description_width = () => {
    return 12 - (invoice.settings.use_qty_price ? 2 : 0) - (invoice.settings.use_units ? 1 : 0) - (invoice.settings.use_vat ? 2 : 0) - 3
  }

  const handleDescriptionInput = (ev) => {
    update_line(index, { description: ev.target.value });
    adjustTextareaHeight(ev.target);
  };

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight + 2}px`;
  };

  return (
    <div className="row border-bottom">
      <div className={`col-lg-${description_width()} py-2`}>
        <div className="field-label">Description:</div>
        <div className="input-group">
          <div class="btn-group">
            <button type="button" class="btn btn-outline-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              Line {index + 1}:&nbsp;
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item text-danger" href="#" onClick={() => delete_line(index)}>Delete this line</a></li>
            </ul>
          </div>
          <textarea
            ref={descriptionRef}
            className="form-control"
            rows="1"
            onInput={handleDescriptionInput}
          >{invoice.lines[index].description}</textarea>
        </div>
      </div>
      {
        invoice.settings.use_qty_price ? (
          <div className="col-sm-6 col-md-3 col-xl-1 py-2">
            <div className="field-label">Qty:</div>
            <input
              type="text"
              className="form-control text-end"
              value={invoice.lines[index].qty}
              onInput={(ev) => update_line(index, { qty: ev.target.value })}
            />
          </div>
        ) : null
      }
      {
        invoice.settings.use_units ? (
          <div className="col-sm-6 col-md-3 col-xl-2 py-1">
            <div className="field-label">Units:</div>
            <input
              type="text"
              className="form-control"
              value={invoice.lines[index].unit}
              onInput={(ev) => update_line(index, { unit: ev.target.value })}
            />
          </div>
        ) : null
      }
      {
        invoice.settings.use_qty_price ? (
          <div className="col-sm-6 col-md-3 col-xl-2 py-2">
            <div className="field-label">Price:</div>
            <input
              type="text"
              className="form-control text-end"
              value={invoice.lines[index].price}
              onInput={(ev) => update_line(index, { price: ev.target.value })}
            />
          </div>
        ) : null
      }
      {
        invoice.settings.use_vat ? (
          <div className="col-sm-6 col-md-3 col-xl-2 py-2">
            <div className="field-label">VAT rate:</div>
            <select className="form-select" onChange={(ev) => update_line(index, { vat_rate: ev.target.value })}>
              <option value="vat_0" selected={invoice.lines[index].vat_rate === "vat_0"}>VAT 0%</option>
              <option value="vat_5" selected={invoice.lines[index].vat_rate === "vat_5"}>VAT 5%</option>
              <option value="vat_20" selected={invoice.lines[index].vat_rate === "vat_20"}>VAT 20%</option>
            </select>
          </div>
        ) : null
      }
      <div className="col-sm-6 col-md-3 col-xl-2 py-2">
        <div className="field-label">Amount:</div>
        <input
          type="string"
          className="form-control text-end"
          value={invoice.lines[index].amount}
          onInput={(ev) => update_line(index, { amount: ev.target.value })}
        />
      </div>
    </div >
  );
}

export default InvoiceLine;