import { useState } from "preact/hooks"
import { BsPencilSquare, BsCheckSquare, BsXSquare } from "react-icons/bs";
import { useAtomState } from "@zedux/react"
import businessesListAtom from "./businessesListAtom"

const BusinessRecordDetails = ({ business }) => {
  const [theBusiness, setTheBusiness] = useState(business)
  const [isEditing, setIsEditing] = useState(false)
  const [businesses, setBusinesses] = useAtomState(businessesListAtom)

  const saveChanges = () => {
    fetch(`/api/businesses/${business.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        lines_for_invoice: document.getElementById("lines_for_invoice").value.split("\n"),
        lines_for_payment_instructions: document.getElementById("lines_for_payment_instructions").value.split("\n"),
        use_vat: document.getElementById("use_vat").checked,
        vat_registration_number: document.getElementById("vat_registration_number").value,
        use_cis: document.getElementById("use_cis").checked,
        cis_deduction_rate: document.getElementById("cis_deduction_rate").value
      })
    }).then(response => {
      if (response.ok) {
        response.json().then(data => {
          if (data.success) {
            const newBusinessesList = businesses.map(b => {
              if (b.id === data.business.id) {
                return data.business
              } else {
                return b
              }
            })
            setBusinesses(newBusinessesList)
            setTheBusiness(data.business)
          } else {
            alert("Errors:\n" + data.errors.join("\n"))
          }
        })
        setIsEditing(false)
      } else {
        alert("An error occurred while saving changes, please check your internet connection.")
      }
    })
  }

  const cancelChanges = () => {
    setIsEditing(!isEditing)
  }

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-lg-4">
          <p><strong>Appears in invoice as:</strong></p>
          <textarea
            className="form-control"
            rows="4"
            id="lines_for_invoice"
            disabled={!isEditing}
          >
            {theBusiness.data.lines_for_invoice.join("\n")}
          </textarea>
        </div>
        <div className="col-lg-4">
          <p><strong>Payment instructions:</strong></p>
          <textarea
            className="form-control"
            rows="4"
            id="lines_for_payment_instructions"
            disabled={!isEditing}
          >
            {theBusiness.data.lines_for_payment_instructions.join("\n")}
          </textarea>
        </div>
        <div className="col-lg-4">
          <table className="table table-sm">
            <tr>
              <td>Use VAT:</td>
              <td>
                <input
                  type="checkbox"
                  className="form-check-input"
                  disabled={!isEditing}
                  checked={theBusiness.data.invoice_settings.use_vat}
                  id="use_vat"
                />
              </td>
            </tr>
            <tr>
              <td>VAT reg number:</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  disabled={!isEditing}
                  value={theBusiness.data.invoice_settings && theBusiness.data.invoice_settings.vat_registration_number}
                  id="vat_registration_number"
                />
              </td>
            </tr>
            <tr>
              <td>Use CIS:</td>
              <td>
                <input
                  type="checkbox"
                  className="form-check-input"
                  disabled={!isEditing}
                  checked={theBusiness.data.invoice_settings.use_cis}
                  id="use_cis"
                />
              </td>
            </tr>
            <tr>
              <td>CIS deduction rate:</td>
              <td>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    disabled={!isEditing}
                    value={theBusiness.data.invoice_settings && theBusiness.data.invoice_settings.cis_deduction_rate}
                    id="cis_deduction_rate"
                  />
                  <span className="input-group-text">%</span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      {
        isEditing ?
          <div className="d-flex">
            <div className="btn btn-sm btn-outline-danger me-2" onClick={cancelChanges}>
              <BsXSquare /> Cancel
            </div>
            <div className="btn btn-sm btn-outline-success" onClick={saveChanges}>
              <BsCheckSquare /> Save
            </div>
          </div> :
          <div className="btn btn-sm btn-outline-primary" onClick={() => setIsEditing(true)}>
            <BsPencilSquare /> Edit
          </div>
      }

    </div >
  )
}

export default BusinessRecordDetails;
