import { useAtomState, useAtomInstance } from '@zedux/react'
import { useState, useEffect, } from 'preact/hooks'
import invoiceAtom from './invoiceAtom'
import { BiFileFind, BiPlusCircle } from "react-icons/bi";
import { FaRegCalendar } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import InvoiceLine from "./InvoiceLine"
import businessesListAtom from './businessesListAtom'
import customersListAtom from "./customersListAtom"
import editSettingsAtom from "./editSettingsAtom"
import invoiceEditorModeAtom from "./invoiceEditorModeAtom"
import InvoiceLinesHeader from "./InvoiceLinesHeader"
import InvoiceSettingsEditor from "./InvoiceSettingsEditor"
import CustomerSelector from "./CustomerSelector"
import BusinessSelector from "./BusinessSelector"
import Totals from "./Totals"
import TotalsCis from "./TotalsCis"

// import EditableTextField from './EditableTextField'


const InvoiceEditor = ({ id }) => {
  const [invoice] = useAtomState(invoiceAtom)
  const [businesses, setBusinesses] = useAtomState(businessesListAtom)
  const [customers, setCustomers] = useAtomState(customersListAtom)
  const [invoiceEditorMode, setInvoiceEditorMode] = useAtomState(invoiceEditorModeAtom) //modes are: edit_invoice, select_customer, select_business
  const [editingSettings, setEditingSettings] = useAtomState(editSettingsAtom)

  const {
    set_invoice,
    update_date,
    update_supply_date,
    update_number,
    add_line,
    update_supplier_data,
    update_customer_data,
    update_settings, // we will add "changeCurrency" later
    update_deliver_to,
    update_purchase_order,
    update_due_date,
    update_payment_instructions,
  } = useAtomInstance(invoiceAtom).exports

  useEffect(() => {
    fetch(`/api/invoices/${id}`)
      .then(response => response.json())
      .then(data => {
        set_invoice(data.invoice)
        setBusinesses(data.businesses)
        setCustomers(data.customers)
      })
  }, [])

  const selectBusiness = () => {
    setInvoiceEditorMode("select_business")
  }

  const selectCustomer = () => {
    setInvoiceEditorMode("select_customer")
  }

  const saveInvoice = () => {
    fetch(`/api/invoices/${id}`, {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ invoice: invoice })
    })
      .then(response => { return response.json() })
      .catch(error => {
        alert("Error:", error)
      })
      .then(data => {
        if (data.success) {
          window.location = `/invoicer/invoices/${id}`
        } else if (data) {
          set_invoice(data.invoice)
          setBusinesses(data.businesses)
          setCustomers(data.customers)
        }
      })
  }

  const SelectBusinessBtn = () => {
    if (businesses && businesses.length > 0) {
      return <span className="me-1" style={{ cursor: "pointer" }}><BiFileFind size="24" onClick={selectBusiness} /></span>
    } else {
      null
    }
  }

  const SelectCustomerBtn = () => {
    if (customers && customers.length > 0) {
      return <span className="me-1" style={{ cursor: "pointer" }}><BiFileFind size="24" onClick={selectCustomer} /></span>
    } else {
      null
    }
  }


  const lines = () => {
    if (invoice && invoice.lines) {
      return (
        <>
          <div class="d-none"><InvoiceLinesHeader /></div>
          <div class="d-block"><hr /></div>
          {invoice.lines.map((line, index) => {
            return (
              <InvoiceLine key={index} index={index} />
            )
          })}
          <div className="btn btn-outline-primary btn-sm my-2" onClick={add_line}>
            <BiPlusCircle />&nbsp;Add a new line
          </div>
        </>
      )
    } else {
      return null
    }
  }
  const SaveBtn = () => {
    if (invoice.updated) {
      return (
        <div className="btn btn-primary" onClick={saveInvoice}>Save invoice</div>
      )
    } else {
      return (
        <div className="btn btn-secondary-primary disabled">Save invoice</div>
      )
    }
  }

  if (invoiceEditorMode == "select_customer") {
    return <CustomerSelector />
  }

  if (invoiceEditorMode == "select_business") {
    return <BusinessSelector />
  }

  return (
    <>
      <div className="float-end">
        <div className="d-float">
          <SaveBtn />
        </div>
      </div>
      {editingSettings ? <InvoiceSettingsEditor /> : null}
      <div className="clearfix" />

      <hr />
      <div className="row">
        <div className="col-lg-5">
          <div className="row">
            <div className="col-5">
              <div className="display-6 nowrap">Invoice</div>
              <div
                className="btn btn-outline-primary my-2"
                onClick={() => { setEditingSettings(!editingSettings) }}>
                <FiSettings />&nbsp;Settings
              </div>

            </div>
            <div className="col-7">
              <div className="d-flex my-2">
                <div className="py-2" style={{ width: "5em" }}>#:</div>
                <input
                  type="text"
                  value={invoice.number}
                  onChange={(e) => update_number(e.target.value)}
                  className="form-control"
                />

              </div>
              <div className="d-flex my-2">
                <div className="py-2" style={{ width: "5em" }}>Date:</div>
                <input
                  type="date"
                  value={invoice.date || ""}
                  onChange={(e) => update_date(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="row">
            <div className="col-lg-3">
              <div className="my-2">
                <label>Due Date:</label>
                <input
                  type="date"
                  value={invoice.due_date || ""}
                  onChange={(e) => update_due_date(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            {
              invoice && invoice.settings && invoice.settings.use_separate_supply_date ? (
                <div className="col-lg-3">
                  <div className="my-2">
                    <label>Supply Date:</label>
                    <input
                      type="date"
                      value={invoice.supply_date || ""}
                      onChange={(e) => update_supply_date(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              ) : null
            }
            {
              invoice && invoice.settings && invoice.settings.use_purchase_order ? (
                <div className="col-lg-3">
                  <div className="my-2">
                    <label>Purchase Order #:</label>
                    <input
                      type="text"
                      value={invoice.purchase_order}
                      onChange={(e) => update_purchase_order(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              ) : null
            }
            {
              invoice && invoice.settings && invoice.settings.use_vat ? (
                <div className="col-lg-3">
                  <div className="my-2">
                    <SelectBusinessBtn />
                    <label>VAT reg number:</label>
                    <input
                      type="text"
                      value={invoice.settings.vat_registration_number}
                      disabled={invoice.business_is_preselected}
                      onChange={(e) => update_settings({ vat_registration_number: e.target.value })}
                      className="form-control"
                    />
                  </div>
                </div>
              ) : null
            }
            {
              invoice && invoice.settings && invoice.settings.use_cis ? (
                <div className="col-lg-3">
                  <div className="my-2">
                    <SelectBusinessBtn />
                    <label>CIS deduction rate:</label>
                    <input
                      type="number"
                      value={invoice.settings.cis_deduction_rate}
                      disabled={invoice.business_is_preselected}
                      onChange={(e) => update_settings({ cis_deduction_rate: e.target.value })}
                      className="form-control"
                    />
                  </div>
                </div>
              ) : null
            }
            <div className="col-lg-3">
              <div className="my-2">
                <label>Currency</label>
                <select className="form-select" value={invoice.settings ? invoice.settings.currency : "GBP"} onChange={(e) => update_settings({ currency: e.target.value })}>
                  <option value="GBP">GBP</option>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div >

      <div className="row">
        <div className="col-lg-4 py-2">
          <div className="d-flex">
            <SelectBusinessBtn />
            <label>Supplier:</label>
          </div>
          <textarea
            className="form-control"
            disabled={invoice.business_is_preselected}
            placeholder="Your name and business name&#10;Your address&#10;Other contact details"
            rows="4"
            onChange={(ev) => update_supplier_data(ev.target.value)}
          >
            {invoice.supplier_data}
          </textarea>
        </div>
        <div className="col-lg-4 py-2">
          <div className="d-flex">
            <SelectCustomerBtn />
            <label>Customer:</label>
          </div>
          <textarea
            className="form-control"
            disabled={invoice.customer_is_preselected}
            placeholder="Customer name&#10;Customer address"
            rows="4"
            onChange={(ev) => update_customer_data(ev.target.value)}
          >
            {invoice.customer_data}
          </textarea>
        </div>
        {
          invoice && invoice.settings && invoice.settings.use_deliver_to ? (
            <div className="col-lg-4 py-2">
              <label>Deliver to:</label>
              <textarea
                className="form-control"
                placeholder="Deliver to address"
                rows="4"
                value={invoice.deliver_to}
                onChange={(ev) => update_deliver_to(ev.target.value)}
              />
            </div>
          ) : null
        }
      </div >
      {lines()}
      <hr />
      <div className="row">
        <div className="col-lg-5 py-2">
          Payment instructions:
          <textarea
            className="form-control"
            disabled={invoice.business_is_preselected}
            placeholder="Account name: My Business&#10;Sort code: 12-34-56&#10;Account number: 12345678"
            rows="4"
            value={invoice.payment_instructions}
            onChange={(ev) => update_payment_instructions(ev.target.value || "")}
          />
        </div>
        <div className="col-lg-2">
        </div>
        <div className="col-lg-5">
          {
            invoice && invoice.settings && (invoice.settings.use_cis ? <TotalsCis /> : <Totals />)
          }
        </div>
      </div>
      <div className="text-end my-2"> <SaveBtn /></div>
    </>
  )
}

export default InvoiceEditor