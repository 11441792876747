import { useMemo, useState } from 'preact/hooks';
import { Box, ButtonGroup, Button, Divider, Drawer, Link, Stack, Typography, Popper } from '@mui/material';

export const LEFT_DRAWER_WIDTH = 240;

import { useDocument, useLeftDrawerOpen } from '../documents/editor/EditorContext';
import SidebarButton from './SidebarButton';

const LeftDrawer = ({ id, name, available_vars }) => {

  const leftDrawerOpen = useLeftDrawerOpen();
  const doc = useDocument();

  const handleCopyClick = (e, variable) => {
    e.preventDefault();
    navigator.clipboard.writeText(`{{${variable}}}`);
    e.target.innerText = 'Copied to clipboard';
    setTimeout(() => {
      e.target.innerText = `{{${variable}}}`;
    }, 1000);
  }

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={leftDrawerOpen}
      sx={{
        width: leftDrawerOpen ? LEFT_DRAWER_WIDTH : 0,
      }}
    >
      <Stack spacing={3} py={1} px={2} width={LEFT_DRAWER_WIDTH} justifyContent="space-between" height="100%">
        <Stack spacing={2} sx={{ '& .MuiButtonBase-root': { width: '100%', justifyContent: 'flex-start' } }}>
          <Typography variant="h4" component="h1" sx={{ p: 0.75 }}>
            Edit email template
          </Typography>
          <div>
            <Link href={`/admin/mail_templates/${id}`}>{name}</Link>
          </div>

          <Stack alignItems="flex">
            <Typography variant="h6" component="h5" sx={{ p: 0.75 }}>
              Available variables:
            </Typography>
            <Stack spacing={1}>
              <ButtonGroup orientation="vertical" aria-label="Vertical button group">
                {available_vars.map((variable) => (
                  <Button key={variable} onClick={(e) => { handleCopyClick(e, variable) }}>{`{{${variable}}}`}</Button>
                ))}
              </ButtonGroup>

            </Stack>
          </Stack>
          <Divider />

          <Stack alignItems="flex-start">
            <Typography variant="h6" component="h3" sx={{ p: 0.75 }}>
              Use predefined:
            </Typography>
            <SidebarButton href="#">Empty</SidebarButton>
            <SidebarButton href="#sample/welcome">Welcome email</SidebarButton>
            <SidebarButton href="#sample/one-time-password">One-time passcode (OTP)</SidebarButton>
            <SidebarButton href="#sample/reset-password">Reset password</SidebarButton>
            <SidebarButton href="#sample/order-ecomerce">E-commerce receipt</SidebarButton>
            <SidebarButton href="#sample/subscription-receipt">Subscription receipt</SidebarButton>
            <SidebarButton href="#sample/reservation-reminder">Reservation reminder</SidebarButton>
            <SidebarButton href="#sample/post-metrics-report">Post metrics</SidebarButton>
            <SidebarButton href="#sample/respond-to-message">Respond to inquiry</SidebarButton>
          </Stack>

          <Divider />

        </Stack>
      </Stack>

    </Drawer>

  )
}

export default LeftDrawer
