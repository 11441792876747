import { useAtomState } from '@zedux/react'
import invoiceAtom from './invoiceAtom'

// Not CIS
const Totals = () => {
  const [invoice] = useAtomState(invoiceAtom)

  const vatLines = () => {
    if (invoice.settings.use_vat === false) {
      return null
    }

    if (invoice.totals.vat === undefined) {
      return null
    }

    let index = 0
    return invoice.totals.vat.map((line) => {
      index += 1
      return (
        <tr key={index}>
          <td className="text-end">VAT at {line.rate * 100.0}%:</td>
          <td className="text-end">{line.vat}</td>
        </tr>
      )
    })
  }

  if (invoice.settings.use_vat) {
    // if we are using VAT
    return (
      <table className="table">
        <tr>
          <td className="text-end">Subtotal:</td>
          <td className="text-end">{invoice.totals.subtotal}</td>
        </tr>
        {vatLines()}
        <tr>
          <td className="text-end">Total:</td>
          <td className="text-end">{invoice.totals.total}</td>
        </tr>
      </table>
    )
  } else { // no VAT
    return (
      <table className="table">
        <tr>
          <td className="text-end">Total:</td>
          <td className="text-end">{invoice.totals.total}</td>
        </tr>
      </table>
    )
  }
}

export default Totals;