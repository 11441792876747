// Entry point for the build script in your package.json
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

import { h, render } from 'preact'

// Import preact rendering functions
window.h = h;
window.render = render;

import React from "preact/compat"
window.React = React

import InvoiceEditor from "./components/InvoiceEditor"
window.InvoiceEditor = InvoiceEditor

import EmailEditor from "./components/EmailEditor/main"
window.EmailEditor = EmailEditor