import { atom, injectStore, api } from '@zedux/react'

const defaultLine = {
  description: "",
  qty: 1,
  price: 0.0,
  amount: 0.0,
  item: "",
  vat_rate: "vat_20"
}

const recalculateTotals = (store) => {
  let subtotal = 0.0
  let vat = []
  let total_vat = 0.0
  let cis_materials = 0.0
  let cis_deduction = 0.0

  store.getState().lines.forEach(line => {
    let amount = parseFloat(line.amount)
    subtotal += amount
    if (line.materials) {
      cis_materials += amount
    }
    if (store.getState().settings.use_vat) {
      const rate = parseFloat(line.vat_rate.split("_")[1]) / 100.0
      let vat_record = vat.find(v => v.rate === rate)
      if (vat_record === undefined) {
        vat.push({ base: 0.0, rate: rate, vat: 0.0 })
        vat_record = vat.find(v => v.rate === rate)
      }
      vat_record.base += amount
      vat_record.vat += amount * rate
      total_vat += amount * rate
    }
  })

  if (store.getState().settings.use_cis) {
    cis_deduction = (subtotal - cis_materials) * (store.getState().settings.cis_deduction_rate / 100.0)
  }

  const totals = {
    subtotal,
    vat,
    total_vat,
    cis_materials,
    cis_deduction,
    cis_payable: subtotal - cis_deduction,
    total: subtotal + total_vat
  }

  store.setStateDeep({
    totals: totals
  })
}

const invoiceAtom = atom('invoice', () => {
  const store = injectStore({})

  return api(store).setExports({
    update_number: (number) => { store.setStateDeep({ number: number, updated: true }) },
    update_date: (date) => store.setStateDeep({ date, updated: true }),
    update_supply_date: (supply_date) => store.setStateDeep({ supply_date, updated: true }),
    update_due_date: (due_date) => store.setStateDeep({ due_date, updated: true }),
    update_supplier_data: (supplier_data) => store.setStateDeep({ supplier_data, updated: true }),
    update_customer_data: (customer_data) => store.setStateDeep({ customer_data, updated: true }),
    update_purchase_order: (purchase_order) => store.setStateDeep({ purchase_order, updated: true }),
    update_deliver_to: (deliver_to) => store.setStateDeep({ deliver_to, updated: true }),
    update_payment_instructions: (payment_instructions) => store.setStateDeep({ payment_instructions, updated: true }),
    add_line: (line) => store.setStateDeep({
      lines: [...store.getState().lines, { ...defaultLine }],
      updated: true
    }),
    update_line: (index, data) => {
      let newLines = [...store.getState().lines]
      const oldLine = newLines[index]

      if (data.qty) { // qty changed, recalculate price
        data.amount = parseFloat(data.qty) * parseFloat(oldLine.price)
      } else if (data.price) { // price changed, recalculate amount
        data.amount = parseFloat(data.price) * parseFloat(oldLine.qty)
      } else if (data.amount) { // amount changed, recalculate price
        if (parseFloat(oldLine.qty) === 0) { // in case when qty still 0
          data.qty = 1
          data.price = parseFloat(data.amount) / parseFloat(data.qty);
        } else {
          data.price = parseFloat(data.amount) / parseFloat(oldLine.qty);
        }
      }

      newLines[index] = { ...newLines[index], ...data }
      store.setStateDeep({ lines: newLines, updated: true })
      recalculateTotals(store)
    },
    delete_line: (index) => {
      let newLines = [...store.getState().lines]
      newLines.splice(index, 1)

      store.setStateDeep({ lines: newLines, updated: true })
      recalculateTotals(store)
    },
    update_settings: (settings) => {
      store.setStateDeep({ settings, updated: true })
      recalculateTotals(store)
    },
    set_invoice: (invoice) => {
      let newInvoice = { ...invoice }
      if (!newInvoice.settings) {
        newInvoice.settings = {
          currency: "GBP",
          use_vat: false,
          use_qty_price: false,
          use_units: false,
          use_cis: false,
          cis_deduction_rate: 20,
        }
      } else {
        if (!newInvoice.settings.currency) {
          newInvoice.settings.currency = "GBP"
        }
      }
      if (!newInvoice.lines) {
        newInvoice.lines = []
      }
      if (newInvoice.lines.length == 0) {
        newInvoice.lines = [...[], defaultLine]
      }
      if (!newInvoice.totals) {
        newInvoice.totals = {
          subtotal: 0.0,
          vat: [],
          total: 0.0,
          cis_materials: 0.0,
          cis_deduction: 0.0,
          cis_payable: 0.0
        }
      }

      if (!newInvoice.supplier_data || newInvoice.supplier_data === "") {
        if (newInvoice.business) {
          newInvoice.supplier_data = newInvoice.business.data.lines_for_invoice.join("\n")
        } else {
          newInvoice.supplier_data = ""
        }
      }
      if (!newInvoice.customer_data || newInvoice.customer_data === "") {
        if (newInvoice.customer) {
          newInvoice.customer_data = newInvoice.customer.data.lines_for_invoice.join("\n")
        } else {
          newInvoice.customer_data = ""
        }
      }
      if (!newInvoice.payment_instructions || newInvoice.payment_instructions === "") {
        if (newInvoice.business) {
          newInvoice.payment_instructions = newInvoice.business.data.lines_for_payment_instructions.join("\n")
        } else {
          newInvoice.payment_instructions = ""
        }
      }

      newInvoice.updated = false
      store.setStateDeep(newInvoice)
    },
    save: () => {
      fetch(`/api/invoices/${store.getState().id}`, {
        method: "PATCH",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ invoice: store.getState() })
      })
        .then(response => { return response.json() })
        .catch(error => {
          alert("Error:", error)
        })
        .then(data => {
          data.invoice.updated = false
          store.setStateDeep(data.invoice)
        })
    }

  });
});

export default invoiceAtom;