import { useState, useEffect } from 'preact/hooks'
import { BsChevronRight, BsChevronDown, BsPencilSquare, BsXSquare, BsCheckSquare } from "react-icons/bs";
import { useAtomState } from '@zedux/react'
import invoiceAtom from "./invoiceAtom"
import customersListAtom from "./customersListAtom"
import invoiceEditorModeAtom from "./invoiceEditorModeAtom"
import CustomerRecordDetails from "./CustomerRecordDetails"

const CustomerRecord = ({ customer }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [theCustomer, setTheCustomer] = useState(customer)
  const [invoice, setInvoice] = useAtomState(invoiceAtom)
  const [customers] = useAtomState(customersListAtom)
  const [invoiceEditorMode, setInvoiceEditorMode] = useAtomState(invoiceEditorModeAtom)

  useEffect(() => {
    setTheCustomer(customers.find(c => c.id === customer.id))
  }, [customers])

  const selectCustomer = () => {
    setInvoice({
      ...invoice, customer: theCustomer, customer_data: theCustomer.data.lines_for_invoice.join("\n"), updated: true,
      customer_id: customer.id, customer_is_preselected: true
    })
    setInvoiceEditorMode("edit_invoice")
  }

  return (
    <div className="card my-2">
      <div className="card-header d-flex">
        <div className="btn btn-outline-success" onClick={selectCustomer}>
          Select
        </div>
        <div className="p-2 flex-grow-1">{theCustomer.name}</div>
        <div className="btn btn-sm p-1" onClick={() => setIsExpanded(!isExpanded)}>
          {
            isExpanded ? <BsChevronDown /> : <BsChevronRight />
          }
        </div>
      </div>
      {isExpanded && <CustomerRecordDetails customer={theCustomer} />}
    </div>
  )
}

export default CustomerRecord