import { useAtomState } from '@zedux/react'
import { useState, useEffect } from 'preact/hooks'
import customersListAtom from "./customersListAtom"
import invoiceAtom from "./invoiceAtom"
import invoiceEditorModeAtom from "./invoiceEditorModeAtom"
import { BsChevronLeft, BsCheckSquare, BsChevronRight, BsChevronDown } from "react-icons/bs";
import CustomerRecord from "./CustomerRecord"

const CustomerSelector = () => {
  const [customers, setCustomers] = useAtomState(customersListAtom)
  const [filteredCustomers, setFilteredCustomers] = useState(customers)
  const [isExpanded, setIsExpanded] = useState(false)
  const [invoiceEditorMode, setInvoiceEditorMode] = useAtomState(invoiceEditorModeAtom) //modes are: edit_invoice, select_customer, select_business
  const [invoice, setInvoice] = useAtomState(invoiceAtom)

  const updateSearch = (e) => {
    const search = e.target.value
    const filtered = customers.filter((customer) => {
      if (invoice.customer && customer.id === invoice.customer.id) {
        // exclude currently selected customer
        return false
      }
      return customer.name.toLowerCase().includes(search.toLowerCase())
    })
    if (invoice.customer !== null) {
      // selected customer first
      setFilteredCustomers([invoice.customer, ...filtered])
    } else {
      // no selected customer
      setFilteredCustomers(filtered)
    }
  }

  const Details = ({ customer }) => {
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-6">
            <p><strong>Appears in invoice as:</strong></p>
            {customer.data.lines_for_invoice.map((line, index) => {
              return (
                <div key={index}>{line}</div>
              )
            })}
          </div>
          <div className="col-6">
          </div>
        </div>
      </div>
    )
  }

  const newCustomer = () => {
    setInvoice({ ...invoice, customer: null, customer_id: null, customer_data: "", customer_is_preselected: false, updated: true })
    setInvoiceEditorMode("edit_invoice")
  }

  const selectCustomer = (customer) => {
    setInvoice({
      ...invoice, customer: customer, customer_data: customer.data.lines_for_invoice.join("\n"), updated: true,
      customer_id: customer.id, customer_is_preselected: true
    })
    setInvoiceEditorMode("edit_invoice")
  }

  return (
    <>
      <div className="clearfix"></div>
      <hr />
      <div className="d-flex">
        <div className="btn" onClick={() => { setInvoiceEditorMode("edit_invoice") }}><BsChevronLeft /></div>
        <div className="p-2 ">
          Selecting a customer
        </div>
        <div className="input-group" style={{ width: "20em" }}>
          <input type="text" className="form-control" placeholder="Search customers" onChange={updateSearch} />
        </div>
      </div>
      <div className="card my-2">
        <div className="card-header d-flex">
          <div className="btn btn-outline-success me-3" onClick={newCustomer}>
            Use new customer
          </div>
          <p>You will add details by entering information in the invoice field "Bill to"</p>
        </div>
      </div>
      {
        filteredCustomers.map(customer => (
          <CustomerRecord customer={customer} key={customer.id} />
        ))
      }
    </>
  )
}

export default CustomerSelector