// import React from 'react';
// import ReactDOM from 'react-dom/client';

import { useEffect } from 'preact/hooks';
// import { RecoilRoot, useSetRecoilState } from 'recoil';
import { useAtomState } from '@zedux/react'

import { CssBaseline, ThemeProvider } from '@mui/material';

import Editor from './Editor';
import theme from './theme';
import { resetDocument } from './documents/editor/EditorContext';
import mailSubjectAtom from "../../atoms/mailSubjectAtom.js";

// interface EmailEditorProps {
//   id: number,
//   name: string,
//   body_json: string,
// }

const EmailEditor = ({ id, name, body_json, subject, subtitle, available_vars, images }) => {
  // const [_, setSubject] = useAtomState(mailSubjectAtom);

  useEffect(() => {

    if (body_json.root === undefined) {
      alert('Provided template is incompatible with current editor version');
    } else {
      resetDocument(body_json);
    }
  }, [body_json]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Editor id={id} name={name} subject={subject} subtitle={subtitle} available_vars={available_vars} images={images} />
    </ThemeProvider>
  );
}

export default EmailEditor;
