import { useAtomState } from '@zedux/react'
import { useState } from 'preact/hooks'
import businessesListAtom from "./businessesListAtom"
import invoiceAtom from "./invoiceAtom"
import invoiceEditorModeAtom from "./invoiceEditorModeAtom"
import { BsChevronLeft, BsCheckSquare, BsChevronRight, BsChevronDown } from "react-icons/bs";
import BusinessRecord from "./BusinessRecord"


const BusinessSelector = () => {
  const [businesses] = useAtomState(businessesListAtom)
  const [invoiceEditorMode, setInvoiceEditorMode] = useAtomState(invoiceEditorModeAtom) //modes are: edit_invoice, select_customer, select_business
  const [invoice, setInvoice] = useAtomState(invoiceAtom)

  const newBusiness = () => {
    setInvoice({
      ...invoice, business: null, business_id: null, supplier_data: "", payment_instructions: "", business_is_preselected: false,
      settings: { ...invoice.settings, vat_registration_number: "", cis_deduction_rate: "" }
    })
    setInvoiceEditorMode("edit_invoice")
  }

  return (
    <>
      <div className="clearfix"></div>
      <hr />
      <div className="d-flex my-2">
        <div className="btn" onClick={() => { setInvoiceEditorMode("edit_invoice") }}><BsChevronLeft /></div>
        <div className="p-2">
          Selecting supplier details
        </div>
      </div>
      <div className="card my-2">
        <div className="card-header d-flex">
          <div className="btn btn-outline-success me-3" onClick={newBusiness}>
            Use new supplier
          </div>
          <p>You will add details by entering information in the invoice fields (suppler data, payment instructions, VAT reg. #, etc)</p>
        </div>
      </div>
      {
        businesses.map(business => (
          <BusinessRecord business={business} key={business.id} />
        ))
      }
    </>
  )
}

export default BusinessSelector