import { useState } from 'preact/hooks'
import { BsPencilSquare, BsXSquare, BsCheckSquare } from "react-icons/bs";
import { useAtomState } from '@zedux/react'
import customersListAtom from "./customersListAtom"


const CustomerRecordDetails = ({ customer }) => {
  const [theCustomer, setTheCustomer] = useState(customer)
  const [isEditing, setIsEditing] = useState(false)
  const [customers, setCustomers] = useAtomState(customersListAtom)

  const saveChanges = () => {
    fetch(`/api/customers/${customer.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        id: customer.id,
        name: theCustomer.name,
        lines_for_invoice: theCustomer.data.lines_for_invoice,
      })
    }).then(response => {
      if (response.ok) {
        response.json().then(data => {
          if (data.success) {
            setCustomers(customers.map(c => {
              return c.id === data.customer.id ? data.customer : c
            }))
          } else {
            alert("Errors:\n" + data.errors.join("\n"))
          }
        })
        setIsEditing(false)
      } else {
        alert("An error occurred while saving changes, please check your internet connection.")
      }
    })
  }

  const cancelChanges = () => {
    setTheCustomer(customer) // reset to original
    setIsEditing(false)
  }

  return (
    <div className="card-body">
      {
        isEditing ?
          <div>
            <label>Customer name (internal):</label>
            <input
              type="text"
              className="form-control"
              value={theCustomer.name}
              onChange={(event) => setTheCustomer({ ...theCustomer, name: event.target.value })}
            />
          </div> : null
      }
      <div className="row">
        <div className="col-6">
          <label>Appears in invoice as (include name in the first line):</label>
          <textarea
            className="form-control"
            rows="4"
            disabled={!isEditing}
            value={theCustomer.data.lines_for_invoice === null ? "" : theCustomer.data.lines_for_invoice.join("\n")}
            onChange={(event) => setTheCustomer({ ...theCustomer, data: { ...theCustomer.data, lines_for_invoice: event.target.value.split("\n") } })}
          />
        </div>
        <div className="col-6">
          {
            isEditing ?
              <div className="d-flex">
                <div className="btn btn-sm btn-outline-danger me-2" onClick={cancelChanges}>
                  <BsXSquare /> Cancel
                </div>
                <div className="btn btn-sm btn-outline-success" onClick={saveChanges}>
                  <BsCheckSquare /> Save
                </div>
              </div> :
              <div
                className="btn btn-sm btn-outline-primary" onClick={() => setIsEditing(true)}>
                <BsPencilSquare /> Edit
              </div>
          }
        </div>
      </div>
    </div >
  )
}

export default CustomerRecordDetails