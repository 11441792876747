import { useState, useMemo } from 'preact/hooks';
import { useAtomState } from '@zedux/react';

import { Save } from '@mui/icons-material';
import { IconButton, Snackbar, Tooltip } from '@mui/material';

import { useDocument } from '../../documents/editor/EditorContext';
// import { renderToStaticMarkup } from '@usewaypoint/email-builder';
import { renderToStaticMarkup } from '../../../email_builder_packages/email-builder';

import mailSubjectAtom from '../../atoms/mailSubjectAtom';
import mailTemplateIdAtom from '../../atoms/mailTemplateIdAtom';
import mailSubTitleAtom from '../../atoms/mailSubTitleAtom';

export default function SaveButton() {
  const [message, setMessage] = useState<string | null>(null);
  const [subject, setSubject] = useAtomState(mailSubjectAtom);
  const [id, setId] = useAtomState(mailTemplateIdAtom);
  const [subTitle, setSubTitle] = useAtomState(mailSubTitleAtom);
  const doc = useDocument();

  const onClick = async () => {
    const data = {
      body_html: renderToStaticMarkup(doc, { rootBlockId: 'root' }),
      body_json: doc,
      subject: subject,
      subtitle: subTitle
    };
    fetch(`/admin/mail_templates/${id}/save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        window.location = `/admin/mail_templates/${id}`;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const onClose = () => {
    setMessage(null);
  };

  return (
    <>
      <IconButton onClick={onClick}>
        <Tooltip title="Share current template">
          <Save fontSize="small" />
        </Tooltip>
      </IconButton>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={message !== null}
        onClose={onClose}
        message={message}
      />
    </>
  );
}
